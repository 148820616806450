body {
    background-color: #F5F8FB;
}

.logo {
    display: block;
    width: 300px;
    object-fit: contain;
    margin: 0 auto 45px auto;
    left: 0;
    right: 0;
}

.btn-login {
    color: #fff;
    background-color: #000034;
    border-color: #000034;
}

.btn-login:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-green {
    color: #fff;
    background-color: #43D19E;
    border-color: #43D19E;
}

.btn-green:hover {
    color: #fff;
    background-color: #29AB7D;
    border-color: #29AB7D;
}

.btn-yellow {
    color: #fff;
    background-color: #F5B759;
    border-color: #F5B759;
}

.btn-yellow:hover {
    color: #fff;
    background-color: #F19C1D;
    border-color: #F19C1D;
}

.td-center {
    text-align: center;
    vertical-align: middle;
}

.sr-only {
    display: none;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
}

.suggestions li {
    padding: 0.25rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #E9EBEF;
    cursor: pointer;
}

.custom-action-dropdown {
    left: -180px !important
}

.custom-export-dropdown {
    left: -69px !important
}

.wrap-label {
    word-wrap: anywhere;
}

/*Time zone select*/
.css-qc6sy-singleValue {
    color: #66799E !important;
}

/*Booking discount field*/
.col-discount-1 {
    padding-right: 0;
}
.col-discount-2 {
    padding-left: 0;
}
.btn-apply-discount {
    margin-bottom: 6px;
}

@media (max-width:768px) {
    .col-discount-1 {
        padding-right: 0.75rem;
    }
    .col-discount-2 {
        padding-left: 0.75rem;
    }
    .empty-label {
        display: none;
    }
    .btn-apply-discount {
        margin-top: -15px;
    }
}

/*HTML integration field*/
.col-html-code-1 {
    padding-right: 0;
}
.col-html-code-2 {
    padding-left: 0;
}

@media (max-width:768px) {
    .col-html-code-1 {
        padding-right: 0.75rem;
    }
    .col-html-code-2 {
        padding-left: 0.75rem;
    }
    .btn-copy {
        width: 100%;
        margin-top: -7px;
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .col-html-code-1 {
        padding-right: 0;
    }
    .col-html-code-2 {
        padding-left: 0;
    }
    .btn-copy {
        width: 100%;
    }
}

/*Accordions*/
.accordion-button {
    background-color: white;
}